import { useState, useCallback } from 'react'
//import { Avo, defaultTrackingProps } from '@thriveglobal/thrive-web-tracking'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import {
    useBannerStack,
    isActionBanner,
    isImageBanner,
    Banner
} from '../../../../../hooks/useBannerDisplay'
import { ImageBanner } from '../ImageBanner'
import { ActionBanner } from '../ActionBanner'
import { Button, Stack } from '@mui/material'
import {
    useCrossAppNavigation,
    useBanners
} from '@thriveglobal/thrive-web-core'

export const FeaturedBanner = () => {
    const navigate = useCrossAppNavigation()
    const { topBanner } = useBannerStack({ banners: useBanners() as Banner[] })

    if (!topBanner) return <></>

    const trackCTAClick = () => {
        // Avo.promptSelected({
        //     ...defaultTrackingProps,
        //     contentId: "",
        //     contentFormatType,
        //     contentSource,
        //     contentSubtype,
        //     contentTitle,
        //     contentType,
        //     contentUrl,
        //     resetId,
        //     resetName,
        //     isOnboarding,
        //     onboardingAttemptNumber,
        //     userId_,
        //     displayText,
        //     featureType: 'journeys',
        //     activityType: 'company_banner_selected'
        // })
    }

    if (isActionBanner(topBanner))
        return (
            <ActionBanner
                action={
                    <Button
                        variant="outlined"
                        size="medium"
                        onClick={() => {
                            trackCTAClick()
                            navigate(topBanner.callToActionUrl)
                        }}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {topBanner.callToActionText}
                        </CoreTypography>
                    </Button>
                }
                message={
                    <Stack>
                        <CoreTypography variant="overline">
                            {topBanner.header}
                        </CoreTypography>

                        <CoreTypography
                            variant="body1"
                            sx={{ fontWeight: 'bold' }}
                        >
                            {topBanner.body}
                        </CoreTypography>
                    </Stack>
                }
            />
        )
    else if (isImageBanner(topBanner))
        return (
            <ImageBanner
                src={topBanner.image.url}
                alt={topBanner.image.alternativeText}
            />
        )
    else return <></>
}
